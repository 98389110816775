/* eslint-disable react/no-array-index-key */
import getEnv from '@/utils/getEnv';
import { getCurrentHub } from '@sentry/nextjs';
import Link from 'next/link';
import { BreadcrumbItem, BreadcrumbItemContainer } from './styles';
import Schema from '@/v2/components/schema';

export type BreadcrumbItem = {
  titulo: string;
  url: string;
};

interface BreadcrumbProps {
  breadcrumbList: BreadcrumbItem[];
  isSubDomain?: boolean;
}

export default function Breadcrumb({ breadcrumbList, isSubDomain = false }: BreadcrumbProps) {
  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const breadcrumbSpan = currentSpan && currentSpan.startChild({ op: 'breadcrumb' });

  const workingList = [...breadcrumbList].filter(item => item.url !== '/');

  workingList.unshift({ titulo: 'Home', url: '/' });

  return (
    <div className="place-content-[center_start] mb-2 flex flex-wrap items-center gap-2">
      {
        workingList.map(item => (
          <BreadcrumbItemContainer key={item.url}>
            <Link href={isSubDomain ? `${getEnv('URL')}${item.url}` : item.url} passHref>
              <BreadcrumbItem title={`Ir para ${item.titulo}`}>{item.titulo}</BreadcrumbItem>
            </Link>
          </BreadcrumbItemContainer>
        ))
      }
      <Schema dados={{
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: workingList.map((item, index) => ({
          '@type': 'ListItem',
          position: index + 1,
          name: item.titulo,
          item: `${getEnv('URL')}${item.url}`,
        })),
      }} />
      {breadcrumbSpan && breadcrumbSpan.finish()}
    </div>
  );
}
